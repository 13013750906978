<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Nombre</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <SelectInputSimpleState
                                            v-model="state.status"
                                        ></SelectInputSimpleState>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div class="btn btn-primary ms-auto" @click="handleAction">
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { defineComponent, onBeforeMount, reactive, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useSnackbar } from 'vue3-snackbar';
import axios from '@/services/useAxios';
import WindowLayout from '@/layouts/Window.layout.vue';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';

export default defineComponent({
    name: 'PermissionCategoryForm',
    components: { WindowLayout, SelectInputSimpleState },
    props: {
        title: { type: String, default: 'Categoria de Permisos' },
        headerTitle: { type: String, default: 'Crear perfil' },
        urlRead: { type: String, required: true },
        urlCreate: { type: String, required: false },
        urlUpdate: { type: String, required: false },
        item: Object,
    },
    setup(props) {
        const snackbar = useSnackbar();
        const state = reactive({
            id: '',
            name: '',
            status: '1',
        });

        const rules = computed(() => ({
            name: { required },
            status: { required },
        }));
        const $v = useVuelidate(rules, state);

        onBeforeMount(() => {
            if (props.item) {
                state.id = props.item.id;
                state.name = props.item.name;
                state.status = props.item.status.toString();
            }
        });

        const handleAction = async () => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                if (props.item) {
                    await axios
                        .put(`${props.urlUpdate || props.urlRead}/${props.item.id}`, {
                            name: state.name,
                            status: Number(state.status),
                        })
                        .then(() => {
                            snackbar.add({
                                type: 'success',
                                text: 'Item ha sido actualizado',
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            error.data.errors.map((e) => {
                                snackbar.add({
                                    type: 'error',
                                    text: e.msg,
                                });
                                return true;
                            });
                        });
                } else {
                    await axios
                        .post(props.urlCreate || props.urlRead, {
                            name: state.name,
                            status: Number(state.status),
                        })
                        .then(async () => {
                            snackbar.add({
                                type: 'success',
                                text: 'Item ha sido agregado',
                            });
                            state.name = '';
                            state.status = '1';
                        })
                        .catch((error) => {
                            console.log(error);
                            error.data.errors.map((e) => {
                                snackbar.add({
                                    type: 'error',
                                    text: e.msg,
                                });
                                return true;
                            });
                        });
                }
            } else {
                console.log($v.value.$errors);
            }
        };
        return { state, handleAction };
    },
});
</script>

<style></style>
